import React from 'react';
import Layout from '../components/layout/layout';
import Breadcrumbs from '../components/breadcrumbs';
import HeadTitle from '../components/head_title';
import { handleSearch } from '../lib/search';
import { useRegionLinkPrefix } from '../hooks/region';
import * as gtag from "../lib/gtag";

export default function Error404() {
	const [searchText, setSearchText] = React.useState('');
	const regionLinkPrefix = useRegionLinkPrefix();
	
	const handleFormSubmit = React.useCallback((e) => {
		e.preventDefault();
		handleSearch(searchText, regionLinkPrefix);

		gtag.search(searchText);
	}, [searchText, regionLinkPrefix]);

	return <Layout>
		<div className="position-relative">
			<HeadTitle
				title="Invalid Page"
			/>

			<Breadcrumbs
				currentPage="Invalid Page"
			/>
			<div className="row">
				<div className="col-lg-6 text-center">
					<img src="/images/invalid-page.svg" alt="illustrations" className="img-fluid" />
				</div>
				<div className="col-lg-6 align-self-center">
					<div>
						<h4 className="text-primary">
							Opps!
							The Page you are
							Looking for is invalid.
						</h4>
						<br/>
						<br/>

						<div className="font-weight-semibold">
							Try searching for something else:
						</div>
						<br/>
						<form onSubmit={handleFormSubmit}>
							<div className="row">
								<div className="col pr-0">
									<input
										type="text"
										placeholder="Search or Paste link E.g https://www.tokopedia.com/seller/item-name.."
										value={searchText}
										onChange={(e) => setSearchText(e.target.value)}
										className="form-control border-radius-left-1"
									/>
								</div>
								<div className="col-auto pl-0">
									<button className="btn btn-dark-custom border-radius-left-0 border-radius-right-1" type="submit">
										Search
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</Layout>;
}