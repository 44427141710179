import React from "react";
import LayoutFluid from './layout_fluid'

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return <LayoutFluid>
    <div className="container">
      {children}
    </div>
  </LayoutFluid>;
}