import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

export interface BreadcrumbPreviousPage {
  link: string;
  label: string;
}

export interface BreadcrumbsProps extends React.HTMLProps<HTMLDivElement> {
  currentPage: string;
  previousPages?: BreadcrumbPreviousPage[];
  activeColor?: string;
}

export default function Breadcrumbs({currentPage, previousPages, activeColor, ...others}: BreadcrumbsProps) {
  const isHomeOnly = !currentPage
  if (!activeColor) {
    activeColor = 'inherit'
  }
  return <>
     <style jsx>{`
      .breadcrumb {
        background-color: transparent;
        font-size: 0.8rem;
      }

      .breadcrumb-item + .breadcrumb-item::before {
        color: ${activeColor};
        content: '>';
      }

      .breadcrumb-item.active {
        color: ${activeColor};
      }
    `}</style>
    <nav aria-label="breadcrumb" {...others}>
      <ol className="breadcrumb">
        {isHomeOnly ? (
          <li className="breadcrumb-item active" aria-current="step">Home</li>
        ) : (
          <li className="breadcrumb-item" aria-current="step">
            <Link href="/"><a>Home</a></Link>
          </li>
        )}
        {!isHomeOnly && (
          <>
            {Array.isArray(previousPages) && previousPages.map((page, index) => (
              <li key={index} className="breadcrumb-item" aria-current="step">
                <Link href={page.link}><a>{page.label}</a></Link>
              </li>
            ))}
            <li className="breadcrumb-item active" aria-current="location">{currentPage}</li>
          </>
        )}
      </ol>
    </nav>
  </>
}

Breadcrumbs.propTypes = {
  currentPage: PropTypes.string,
  previousPages: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  activeColor: PropTypes.string,
}